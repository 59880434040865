<template>
  <div class="g_page_box">
    <g-loading :loading="loading" />
    <div class="g_main_content">
      <div class="page_items" v-if="hospital_info">
        <div class="page_top">
          <div class="page_img">
            <img
              src="../../assets/img/hospital_example.jpeg"
              alt=""
              width="100%"
            />
          </div>
          <div class="page_text">
            <div class="page_name">{{hospital_info.hospital_name}}</div>
            <div class="page_level">
              <span>{{hospital_info.level}}</span>
              {{hospital_info.attribute}}
            </div>
          </div>
        </div>
        <div class="page_bottom">
          <div class="page_left">
            <div class="page_address">{{hospital_info.address}}</div>
            <div class="page_link">
              <a :href="hospital_info.home_page">查看医院主页</a> 
            </div>
          </div>
          <div class="page_right">
            <div class="page_map">
              <van-icon name="location" color="#1890FF" size="14" />
              <span>地图</span>
            </div>
            <div class="page_phone">
              <van-icon name="phone" color="#1890FF" size="14" />
              <span>电话</span>
            </div>
          </div>
        </div>
      </div>

      <div class="page_partment" v-if="data">
        <div class="partment_left">
          <div class="partment_letf-item" @click="choose_out(index)" :class="index===active_index?'active':''" v-for="(item, index) in data.departments" :key="index">
            {{item.name}}
          </div>
        </div>
        <div class="partment_right">
          <div class="partment_right-item" @click="jump(it)" v-for="(it, idx) in active_list" :key="idx">
           <div class="van-ellipsis">
             {{it.name}}
           </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { hospital_info } from './service'
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      loading: false,
      id: null,
      data: null,
      active_index: 0,
      active_list: [],
    };
  },
  computed: {
    ...mapState("hospital", {
      hospital_info: (state) => state.hospital_info,
    }),
    
  },
  components: {},
  watch: {},
  created() {
    document.title = this.hospital_info.hospital_name
    this.id = this.$route.query.id
    this.get_info(this.id)
  },
  destroyed() {},
  methods: {
    ...mapMutations("hospital", ["updateHospital"]),
    async get_info(id){
      this.loading = true
      try {
        const {data} = await hospital_info({hospital_id: id})
        this.data = data
        this.active_list = this.data.departments[0].secondary
      }finally {
        this.loading = false
      }
    },
    choose_out(idx) {
      this.active_index = idx
      this.active_list = this.data.departments[idx].secondary
    },
    jump(item){
      this.hospital_info.department = item.name
      this.updateHospital(this.hospital_info)
      this.$router.push({
        path: '/select-doctor',
        query: {
          hospital_id: this.id,
          department_code: item.code,
          department_name: item.name
        }
      })
    },
  },
};
</script>
<style scoped>
.g_main_content {
  background: #f2f7f8;
  padding-bottom: 0;
}

.page_items {
  background: #ffffff;
  padding: 12px;
  border-radius: 8px;
  margin: 12px;
}

.page_top {
  display: flex;
}

.page_img {
  width: 80px;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;
}

.page_text {
  padding-left: 12px;
  flex: 1;
}

.page_name {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #242938;
  margin-bottom: 4px;
}

.page_level {
  font-size: 12px;
  line-height: 17px;
  color: #565656;
  margin-bottom: 4px;

  & span {
    display: inline-block;
    width: 56px;
    height: 18px;
    background: rgba(24, 144, 255, 0.1);
    border-radius: 2px;
    font-size: 12px;
    line-height: 18px;
    color: #1890ff;
    text-align: center;
    margin-right: 8px;
  }
}

.page_bottom {
  padding-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page_address,
.page_link {
  font-size: 12px;
  line-height: 17px;
  color: #898995;
  max-height: 17px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 2px;
}

.page_address {
  color: #242938;
}

.page_right {
  display: flex;
}

.page_map,
.page_phone {
  color: #565656; 
  font-size: 12px;
  line-height: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 12px;

  & span {
    margin-top: 4px;
  }
}

.page_partment {
  display: flex;
  background: #fff;
}

.partment_left {
  width: 34%;
  height: calc(100vh - 158px);
  overflow-y: auto;
  background: #F2F7F8;
}

.partment_right {
  width: 66%;
  height: calc(100vh - 158px);
  overflow-y: auto;
}

.partment_letf-item {
  height: 60px;
  position: relative;
  font-size: 14px;
  color: #656565;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  text-align: center;

  &.active {
    background: #fff;
    color: #292929;

    &::before {
      content: "";
      position: absolute;
      width: 3px;
      height: 36px;
      background: #0088FF;
      left: 0;
      top: 7px;
    }
  }
}

.partment_right-item {
  height: 60px;
  position: relative;
  font-size: 14px;
  color: #292929;
  display: flex;
  align-items: center;
  padding: 0 12px;
}
</style>
