import api from '../../utils/api'

// 授权
export async function hospital_info (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         hospital: {
  //           hospital_id:1,
  //           hospital_name: '罗湖区人民医院',
  //           level: '三级甲等',
  //           attribute: '公立医院',
  //           address: '深圳市罗湖区友谊路47号',
  //           lat: '',
  //           lng: '',
  //           phone: 12343555,
  //           home_page: 'www.baidu.com'
  //         },
  //         departments: [
  //           {
  //             name: '内科',
  //             code: 1,
  //             secondary: [
  //               {
  //                 code: 11,
  //                 name: '内分泌科'
  //               },
  //               {
  //                 code: 11,
  //                 name: '消化内科'
  //               },
  //               {
  //                 code: 11,
  //                 name: '呼吸内科'
  //               },
  //               {
  //                 code: 11,
  //                 name: '肾内科'
  //               },
  //               {
  //                 code: 11,
  //                 name: '肿瘤精准医学医学医学医学肿瘤精准医学医学医学医学'
  //               },
  //               {
  //                 code: 11,
  //                 name: '血液内科'
  //               },
  //             ]
  //           },
  //           {
  //             name: '外科',
  //             code: 1,
  //             secondary: [
  //               {
  //                 code: 11,
  //                 name: '内科2'
  //               },
  //               {
  //                 code: 11,
  //                 name: '内科3'
  //               },
  //               {
  //                 code: 11,
  //                 name: '内科4'
  //               },
  //               {
  //                 code: 11,
  //                 name: '内科5'
  //               }
  //             ]
  //           },
  //           {
  //             name: '妇产科',
  //             code: 1,
  //             secondary: [
  //               {
  //                 code: 11,
  //                 name: '内科3'
  //               },
  //               {
  //                 code: 11,
  //                 name: '内科3'
  //               },
  //               {
  //                 code: 11,
  //                 name: '内科4'
  //               }
  //             ]
  //           },
  //           {
  //             name: '皮肤科',
  //             code: 1,
  //             secondary: [
  //               {
  //                 code: 11,
  //                 name: '内科3'
  //               },
  //               {
  //                 code: 11,
  //                 name: '内科3'
  //               },
  //               {
  //                 code: 11,
  //                 name: '内科4'
  //               }
  //             ]
  //           },
  //           {
  //             name: '口腔科（春风园区）',
  //             code: 1,
  //             secondary: [
  //               {
  //                 code: 11,
  //                 name: '内科3'
  //               },
  //               {
  //                 code: 11,
  //                 name: '内科3'
  //               },
  //               {
  //                 code: 11,
  //                 name: '内科4'
  //               }
  //             ]
  //           },
  //         ]
  //       }
  //     })
  //   }, 500)
  // })

  return api.get(`/api/v1/mp/register/departments`, {params:payload})
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/estimate`,payload)
}







